import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useSelector  } from "react-redux";
import { CircularProgress, Popper, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import theme from "../../../theme";
import Button from "../../ui/Button";
import { Brand } from "../../../interfaces/Brand";
import { fetchAssemblyGroups, fetchBrands } from "../../../services/tecdocService";
import { AssemblyGroup } from "../../../interfaces/AssemblyGroup";
import { LoadingButton } from "@mui/lab";
import { LinkingTargetState } from "../../../store/reducers/linkingTargetReducer";

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: "none !important",
  borderRadius: 0,
  backgroundColor: "#F1F1F1 !important",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.primary,
}));

const BrandProductGroupSearch = () => {
  const linkingTargetType = useSelector((state: { linkingTarget: LinkingTargetState}) => state.linkingTarget.linkingTargetType);

  const [openBrands, setOpenBrands] = React.useState(false);
  const [brands, setBrands] = React.useState<readonly Brand[]>([]);
  const [selectBrand, setSelectBrand] = React.useState<Brand | null>(null)
  const [errorBrand, setErrorBrand] = React.useState<string | null>(null)
  const loadingBrands = openBrands && brands.length === 0;

  const [openProductGroups, setOpenProductGroups] = React.useState(false);
  const [assemblyGroups, setAssemblyGroups] = React.useState<AssemblyGroup[]>([]);
  const [selectAssemblyGroup, setSelectAssemblyGroup] = React.useState<AssemblyGroup | null>(null);
  const [isLoadingProductGroup, setIsLoadingProductGroup] = React.useState(false);
  const assemblyGroupInputRef = React.useRef<HTMLInputElement>(null);
  const loadingProductGroups = openProductGroups && assemblyGroups.length === 0;

  const [isLoadingData, setIsLoadingData] = React.useState(false);

  // Autocomplete for Search by brand
  React.useEffect(() => {
    let activeBrand = true;

    if (!loadingBrands) {
      return undefined;
    }

    (async () => {
      try{

      if (activeBrand) {
        const data = await fetchBrands();
        setBrands(data || []);
        setErrorBrand(null);
      }
    } catch (error) {
      console.error("Error fetching Brands:", error);
      setErrorBrand("Not able to fetch brands, Please try again.");
    }
    })();

    return () => {
      activeBrand = false;
    };
  }, [loadingBrands]);

  const handleBrands = async (event: React.ChangeEvent<{}>, brand: Brand | null) => {
    setSelectBrand(brand);
    setOpenProductGroups(true);

    if (brand) {
      try {
        setIsLoadingProductGroup(true);
        const assemblyGroups = await fetchAssemblyGroups(brand.dataSupplierId, linkingTargetType);
        if (!assemblyGroups) return;
        setAssemblyGroups(assemblyGroups);
      } catch (error) {
        console.error("Error fetching articles by brand:", error);
      } finally {
        setIsLoadingProductGroup(false);
      }
      
    }
  }

  const handleAssemblyGroup = async (event: React.ChangeEvent<{}>, assemblyGroup: AssemblyGroup | null) => {
    setSelectAssemblyGroup(assemblyGroup);
  }

  React.useEffect(() => {

    if (selectBrand && assemblyGroupInputRef.current) {
      assemblyGroupInputRef.current.focus();
    }

    if (!openBrands) {
      setBrands([]);
    }
  }, [openBrands, selectBrand]);

  React.useEffect(() => {
    let activeAssemblyGroups = true;

    if (!loadingProductGroups) {
      return undefined
    }

    setAssemblyGroups(assemblyGroups)

    if (!openProductGroups) {
      setAssemblyGroups([]);
    }

    return () =>{
      activeAssemblyGroups = false; 
    }
  }, [assemblyGroups, loadingProductGroups, openProductGroups]);

  const handleSubmitBrandData = () => {
    setIsLoadingData(true);
    const dataSupplierId = selectBrand?.dataSupplierId;
    const assemblyGroupNodeId = selectAssemblyGroup?.assemblyGroupNodeId;
    if (dataSupplierId && assemblyGroupNodeId) {
      const url = `/search-products?brand=${dataSupplierId}&assemblyGroupNodeIds=${assemblyGroupNodeId}`;
      window.location.href = url;
      setIsLoadingData(false);
    }
  }

  return (
    <Box sx={{ paddingLeft: 4, paddingRight: 4 }}>
      <Item
        sx={{
          display: "flex",
          alignItems: "center",
          paddingBottom: "0 !important",
        }}
      >
        <img
          src="/search_plus.svg"
          alt="Search car icon"
          style={{ width: "30px", marginRight: "8px" }} // Added margin for spacing
        />
        <Typography variant="body1" align="left" sx={{ fontSize: "13px" }}>
        Search by brands and product groups
        </Typography>
      </Item>
      <Box sx={{display: "flex", justifyContent:"flex-start", padding: {xs: 0, sm: 0, md: 1}}}>
          <Box flex={1}>
          <Item
            sx={{
              padding: 0,
            }}
          >
            <Box
              sx={{
                backgroundColor: theme.palette.secondary.main,
                width: "40px",
                fontSize: "1.2rem",
                float: "left",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
              }}
            >
              1
            </Box>
            <Autocomplete
              disablePortal
              id="brands"
              open={openBrands}
              onOpen={() => {
                setOpenBrands(true);
              }}
              onClose={() => {
                setOpenBrands(false);
              }}
              ListboxProps={{
                style: { maxHeight: 200, overflowY: "auto" },
              }}
              PaperComponent={(props) => {
                const anchorEl = document.getElementById("brands");
                const width = anchorEl
                  ? `${anchorEl.offsetWidth + 60}px`
                  : "auto";

                return (
                  <Popper
                    {...props}
                    open={openBrands}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    modifiers={[
                      {
                        name: "offset",
                        options: {
                          offset: [-7, -10], // Adjust this value to move the Popper up or down
                        },
                      },
                    ]}
                    style={{
                      zIndex: 9999,
                      fontSize: '13px',
                      fontFamily: "Inter",
                      border: "1px solid #f1f1f1",
                      boxShadow: "2px 3px 3px #ccc",
                      position: "relative",
                      background: "#fff",
                      width: width,
                    }}
                  />
                );
              }}
              isOptionEqualToValue={(option, value) =>
                option.mfrName === value.mfrName
              }
              getOptionLabel={(option) => option.mfrName}
              options={brands}
              loading={loadingBrands}
              sx={{
                width: { xs: "70%", sm: "70%", md: "70%" },
                float: "left",
                backgroundColor: "#fff",
                ".MuiInputBase-input": {
                  height: "40px",
                  marginTop: "-15px",
                  marginBottom: "0px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                },
              }}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  <img
                    loading="lazy"
                    width="30"
                    srcSet={`${option.dataSupplierLogo?.imageURL200}`}
                    src={`${option.dataSupplierLogo?.imageURL200}`}
                    alt=""
                  />
                  {option.mfrName}
                </Box>
              )}
              onChange={handleBrands}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Brands"
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    sx: {
                      color: theme.palette.text.primary,
                      fontSize: "13px",
                      fontFamily: "Inter",
                      height: "100%",
                      top: "-6px",
                      transform: "translate(24px, 12px), scale(1) !important",
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      borderRadius: 0, // Adjust the height as needed
                      height: "40px",
                      fontSize: '13px',
                      fontFamily: "Inter",
                    },
                    endAdornment: (
                      <React.Fragment>
                        {loadingBrands ? (
                          <CircularProgress
                            color="inherit"
                            size={15}
                            sx={{ marginTop: "-15px" }}
                          />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Item>
          </Box>
          <Box flex={1}>
          <Item
            sx={{
              padding: 0,
            }}
          >
            <Autocomplete
              disablePortal
              id="productGroups"
              open={openProductGroups && !!selectBrand}
              onOpen={() => {
                setOpenProductGroups(true);
              }}
              onClose={() => {
                setOpenProductGroups(false);
              }}
              ListboxProps={{
                style: { maxHeight: 200, overflowY: "auto" },
              }}
              PaperComponent={(props) => {
                const anchorEl = document.getElementById("productGroups");
                const width = anchorEl
                  ? `${anchorEl.offsetWidth + 60}px`
                  : "auto";

                return (
                  <Popper
                    {...props}
                    open={openProductGroups}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    modifiers={[
                      {
                        name: "offset",
                        options: {
                          offset: [-7, -10], // Adjust this value to move the Popper up or down
                        },
                      },
                    ]}
                    style={{
                      zIndex: 9999,
                      fontSize: "13px",
                      fontFamily: "Inter",
                      border: "1px solid #f1f1f1",
                      boxShadow: "2px 3px 3px #ccc",
                      position: "relative",
                      background: "#fff",
                      width: width,
                    }}
                  />
                );
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={`${option.assemblyGroupNodeId}-${option.parentNodeId}`}>
                    {option.assemblyGroupName} ({option.count})
                  </li>
                );
              }}
              
              isOptionEqualToValue={(option, value) =>
                option.assemblyGroupNodeId === value.assemblyGroupNodeId
              }
              getOptionLabel={(option) => option.assemblyGroupName}
              options={assemblyGroups}
              loading={loadingProductGroups}
              sx={{
                width: { xs: "100%", sm: "100%", md: "100%" },
                backgroundColor: "#fff",
                ".MuiInputBase-input": {
                  height: "40px",
                  marginTop: "-15px",
                  marginBottom: "0px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                },
              }}
              onChange={handleAssemblyGroup}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputRef={assemblyGroupInputRef}
                  label="Product Groups"
                  autoFocus={!!selectBrand}
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    sx: {
                      color: theme.palette.text.primary,
                      fontSize: "13px",
                      fontFamily: "Inter",
                      height: "100%",
                      top: "-6px",
                      transform: "translate(24px, 12px), scale(1) !important",
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      borderRadius: 0, // Adjust the height as needed
                      height: "40px",
                      fontSize: "13px",
                      fontFamily: "Inter",
                    },
                    endAdornment: (
                      <React.Fragment>
                        {loadingProductGroups ? (
                          <CircularProgress
                            color="inherit"
                            size={15}
                            sx={{ marginTop: "-15px" }}
                          />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Item>
          </Box>
      </Box>
      
      <Box flex={1}>
      {isLoadingData ? (
          <LoadingButton loading variant="contained"
              sx={{ 
                height: '38px',
                borderRadius: 0,
                width: "100%",
                alignItems: 'center', 
                justifyContent: 'flex-end'
              }} 
              >
                Submit
          </LoadingButton>
        ) :(
        <>
          <Button
            sx={{
              width: "100%",
              mt:1,
              borderRadius: 0,
              textTransform: 'capitalize',
              backgroundColor: "secondary.main",
              color: "text.secondary",
              ":hover": {
                backgroundColor: "secondary.main",
              }
            }}
            onClick={handleSubmitBrandData}
            disabled={!selectAssemblyGroup}>
            Search
          </Button>
        </>
        )}
      </Box>
    </Box>
  );
};

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: "Pulp Fiction", year: 1994 },
];

export default BrandProductGroupSearch;
