import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Container from "@mui/material/Container";
import SearchProduct from "./Search";
import Menu from "@mui/material/Menu";
import Grid from "@mui/material/Grid";
import BasketDropdown from "./BasketDropdown";
import MainMenu from "./Menu";
import UserDropdown from "./UserDropdown";
import MobileMenu from "./MobileHeader";
import theme from "../../../theme";
import { Typography } from "@mui/material";
import { BannerNotification } from "../../../interfaces/core/BannerNotification";
import { getBannerNotification } from "../../../services/core-api";

const Header = () => {
  const [bannerNotification, setBannerNotification] = React.useState<BannerNotification | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const fetchBannerNotification = async () => {
    try {
      const response = await getBannerNotification();
      if (response) {
        setBannerNotification(response);
      }
    }catch (error){}
  }

  React.useEffect(() => {
    fetchBannerNotification();
  }, [])

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const isTablet = window.innerWidth <= 905;

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <Box
        sx={{
          pt: { xs: 2, sm: 2, md: '0 !important' },
          bgcolor: "primary.main",
          flexGrow: { xs: 0, sm: 0, md: 1 },
        }}
      >
        <Container maxWidth="lg">
          <MobileMenu></MobileMenu>
          <UserDropdown></UserDropdown>
          <AppBar
            position="static"
            sx={{ bgcolor: "primary.light", boxShadow: "none", 
              height: {
                xs: "55px",
                sm: "60px", 
                md: "70px",
            }, 
          }}
          >
            <Toolbar 
            sx={{ 
              minHeight: {
                xs: "55px !important",
                sm: "60px !important",
                md: "70px", 
              },
              padding: 0,  
              mt: 2
            }}>
              <Grid 
                container 
                spacing={3} 
                columns={{ 
                  xs: 12, 
                  sm: 12, 
                  md: 12 
                }}
                sx={{ alignItems: "center" }}
              >
                {!isTablet && (
                  <Grid item md={3} sx={{paddingTop: '0px !important'}}>
                    <Box
                      sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
                    >
                      <img
                        style={{ width: "auto", height: "70px" }}
                        src="./logo.png"
                        alt="Logo"
                      />
                    </Box>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={7}
                  sx={{
                    paddingTop: "0px !important",
                    paddingLeft: {
                      xs: "12px !important",
                      sm: "0px !important",
                      md: "5px !important",
                    },
                    paddingRight: { xs: 0, xm: 0, md: 1 },
                    marginTop: { xs: "0rem", md: "0rem" },
                  }}
                >
                  <SearchProduct></SearchProduct>
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{
                    paddingLeft: 2,
                    paddingTop: '0px !important',
                    marginTop: { xs: "0", md: "0rem" },
                    display: { xs: "none", md: "flex" },
                  }}
                >
                  <BasketDropdown></BasketDropdown>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <MainMenu></MainMenu>
          {renderMobileMenu}
          {renderMenu}
        </Container>
        {bannerNotification && (
          <Grid
          item
          xs={12}
          sx={{
            color: theme.palette.text.secondary,
            background:
              "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,103,0,0.7147233893557423) 0%, rgba(255,71,26,1) 100%)",
          }}
        >
          <Typography sx={{ paddingLeft: 2, paddingRight: 2 }}>
            {bannerNotification?.description}
          </Typography>
        </Grid>
        )}
        
      </Box>
    </>
  );
};

export default Header;
