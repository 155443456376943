import * as React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../../store/store";
import { styled } from "@mui/material/styles";
import { BasketItem } from "../../interfaces/core/BasketItem";
import Paper from "@mui/material/Paper";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { Article } from "../../interfaces/Article";
import { addProductToBasket, checkFavoriteItem, getLiveArticleState } from "../../services/core-api";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Slide, { SlideProps } from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Fade from "@mui/material/Fade";
import { 
   Alert, 
   Box, 
   Grid, 
   Skeleton, 
   Tooltip,
   Card,
   CardContent,
   CardMedia,
   Popover,
   Typography,
   Button,
   Checkbox,
   useMediaQuery,
   MenuList,
   MenuItem,
   TextField,
   Stack,
   CardActions,
   IconButton,
   CardHeader
} from "@mui/material";
import theme from "../../theme";
import ProductTabs from "./productTabs/ProductTabs";
import { addToCart } from "../../store/reducers/basket";
import { useSnackbar } from "../common/SnackbarContext";
import { LoadingButton } from "@mui/lab";
import { Warehouse } from "../../interfaces/core/Warehouse";


interface State extends SnackbarOrigin {
  openNotification: boolean;
}

function SlideTransition(props: SlideProps) {
   return <Slide {...props} direction="up" />;
 }
 
 const CustomIcon = styled(ContentCopyOutlinedIcon)({
   marginLeft: 10,
   marginRight: 0,
 });

const Item = styled(Paper)(({ theme }) => ({
   backgroundColor: "transparent",
   color: "#000",
   boxShadow: "none",
   ...theme.typography.body2,
   padding: theme.spacing(1),
   textAlign: "center",
 }));

const LocalProductItem = (props: any) => {
  const { article } = props;
  const [loading, setLoading] = React.useState(true);
  const [erpProduct, setErpProduct] = React.useState<any | null>(null);
  const defaultImage = "./no-image.png";
  const [openBasketNotification, setOpenBasketNotification] =
    React.useState(false);
  const [basketItem, setBasketItem] = React.useState<BasketItem | null>(null);
  const [value, setValue] = React.useState(0);
  const [isFavorite, setIsFavorite] = React.useState<Boolean | null>(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [price, setPrice] = React.useState(0);
  const [price_2, setPrice_2] = React.useState(0);
  const [warehouses, setWerhauses] = React.useState<Warehouse[] | []>([]);
  const [isloadingStock, setIsLoadingStock] = React.useState(true);
  const [quantity, setQuantity] = React.useState(0);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const { showSnackbar } = useSnackbar();
  const retailPrice = useSelector((state: RootState) => state.priceReducer.retailPrice);
  const [currency, setCurrency] = React.useState('');
  const [currency_2, setCurrency_2] = React.useState('');

  React.useEffect(() => {
    const authUser = localStorage.getItem("user"); 
  
    if (authUser) {
        const user = JSON.parse(authUser);
        if (user.userType === 'servis'){
          setCurrency('LEK');
          setCurrency_2('LEK');
        } else {
          setCurrency('EUR');
          setCurrency_2('LEK');
        }
    }
}, []);


  React.useEffect(() => {
    if (article) {
      const productId = article.productId ? article.productId : article.id;
      const erpId = article.erp_id;
 
      const articleNumber = article.articleNumber.replace(/\//g, "*");
      const dataSupplierId = article.dataSupplierId;
      if (productId && articleNumber && dataSupplierId) {
        (async () => {
          try {
            const erpArticle = await getLiveArticleState(erpId);
            if (erpArticle) {
              setPrice(erpArticle.price);
              setPrice_2(erpArticle.retail_price);
              article.price = erpArticle.price;
              article.offerPrice = erpArticle.retail_price;
              setWerhauses(erpArticle.magazina);
              setErpProduct(erpArticle);
              const total_quantity = erpArticle.qty;
              setQuantity(total_quantity);
              if (total_quantity > 0) {
                setValue(1);
              } else {
                setValue(0);
              }
            }
            setIsLoadingStock(false);
            const status = await checkFavoriteItem(productId);
            setIsFavorite(status);
          } catch(error){
            setIsLoadingStock(false);
            setQuantity(-1);
            setPrice(0.0);
            setPrice_2(0.0);
            setValue(0);
            article.total_quantity = -1;
            setIsLoadingStock(false);
          }
        })()
      }
      
    }
  }, [article])

  const hideBasketNotification = () => {
    setStateBasketNotification({
      ...stateBasketNotification,
      openNotification: false,
    });
  };

  const [stateBasketNotification, setStateBasketNotification] =
    React.useState<State>({
      openNotification: false,
      vertical: "top",
      horizontal: "center",
    });

  // Add article to basket
  const addToBasket = async (article: Article, newStateBasketNotification: SnackbarOrigin) => {
    setStateBasketNotification({
      ...newStateBasketNotification,
      openNotification: true,
    });
    setOpenBasketNotification(true);
    const item: BasketItem = {
      productId: article.productId || article.id,
      erp_id: article.erp_id,
      articleNumber: article.articleNumber,
      mfrName: article.mfrName,
      dataSupplierId: article.dataSupplierId,
      image: '',
      description: article.description,
      price,
      quantity: value,
      totalPrice: parseFloat((price * value).toFixed(2)),
      available: true
    };

    setBasketItem(item);
    const user = localStorage.getItem("user");
    if (user) {
      const parsedUser = JSON.parse(user);
      const user_id = parsedUser.id;
      
        try {
          await addProductToBasket(user_id, item);
          dispatch(addToCart(item));
          showSnackbar("Product added to basket successfully!", "success");
        } catch (error) {
          showSnackbar("Failed to add product to basket.", "error");
        }
    }
  };

  React.useEffect(() => {
    const handleResize = () => {
      setAnchorEl(null);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { vertical, horizontal, openNotification } = stateBasketNotification;

  const handleChange = (event: any) => {
    // Check if the input value is greater than the maximum number
    if (event.target.value !== "" && parseInt(event.target.value) > 100) {
      // If it exceeds the maximum, set the value to the maximum allowed
      setValue(200);
    } else if (parseInt(event.target.value) < 1) {
      // If it exceeds the maximum, set the value to the maximum allowed
      setValue(1);
    } else {
      // Otherwise, update the value normally
      setValue(event.target.value);
    }
  };

  const [state, setState] = React.useState<{
    open: boolean;
    Transition: React.ComponentType<
      TransitionProps & {
        children: React.ReactElement<any, any>;
      }
    >;
  }>({
    open: false,
    Transition: Fade,
  });

  const handlePopoverOpen = (event: any) => {
   setAnchorEl(event.currentTarget);
 };

 const handlePopoverClose = () => {
   setAnchorEl(null);
 };

  const handleClick =
    (
      Transition: React.ComponentType<
        TransitionProps & {
          children: React.ReactElement<any, any>;
        }
      >
    ) =>
      async () => {
        try {
          await navigator.clipboard.writeText(article.articleNumber);
          showSnackbar('Product article number copied!', 'success');
        } catch (error) {
          showSnackbar('Failed to copy article number.', 'error');
        }
  
        setState({
          open: false,
          Transition,
        });
      };

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return (
   <>
       {article ? (
        <>
        {isMobile ? (
          <>
            <Card sx={{ maxWidth: '100%', mb: 2 }}>
                <CardHeader
                  sx={{
                    backgroundColor: "#f1f1f1",
                    display: "flex",
                    alignItems: "center",
                    // Ensure the content area is flex container
                    "& .MuiCardHeader-content": {
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start", // Align items to the left
                      flexGrow: 1,
                      ml: 1, // Optional margin to space content from avatar
                    },
                    "& .MuiCardHeader-title": {
                      fontFamily: "Inter",
                      fontSize: "12px",
                      whiteSpace: "nowrap", // Prevent text wrapping
                    },
                    "& .MuiCardHeader-subheader": {
                      color: "red",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      whiteSpace: "nowrap", // Prevent text wrapping
                    },
                    "& .MuiCardHeader-avatar": {
                      // Apply styles to the avatar slot
                      mr: 0,
                      minWidth: "40px",
                      "& .MuiButton-root": {
                        minWidth: "40px",
                        "&:focus, &:active": {
                          backgroundColor: "transparent",
                        },
                      },
                    },
                  }}
                  avatar={
                    <Button
                      sx={{
                        minWidth: "40px",
                        "&:focus, &:active": {
                          backgroundColor: "transparent",
                        },
                      }}
                      startIcon={<CustomIcon />}
                      onClick={handleClick(SlideTransition)}
                    ></Button>
                  }
                  action={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1, // Spacing between elements
                      }}
                    >
                      <Button
                        aria-label="box"
                        startIcon={
                          <img
                            style={{ width: "24px", height: "24px" }}
                            src="./available_status.svg"
                            alt="product status icon"
                          />
                        }
                        sx={{
                          minWidth: "40px",
                          ml: 2,
                          height: "33px",
                          padding: "4px 2px !important",
                          background: quantity < 1 ? "#F33" : "#0C0",
                          "& .css-1d6wzja-MuiButton-startIcon": {
                            marginRight: "0px !important",
                            marginLeft: "0px !important",
                          },
                          borderRadius: 0,
                          ":hover": {
                            background: quantity < 1 ? "#F33" : "#0C0",
                          },
                        }}
                      ></Button>
                       <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: "none",
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <MenuList
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                    >
                      {warehouses.map((warehouse, index) => (
                            <MenuItem key={index}>
                              <span
                                style={{
                                  background:
                                  warehouse.situation < 1 ? "#F33" : "#0C0",
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "15px",
                                }}
                              ></span>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontFamily: "Inter",
                                  fontWeight: 500,
                                  display: "flex",
                                  alignItems: "center",
                                  ml: 1,
                                }}
                              >
                                {warehouse.serviceUnitCode}
                              </Typography>
                            </MenuItem>
                            ))}
                    </MenuList>
                  </Popover>
                      <TextField
                        disabled={quantity < 1}
                        variant="outlined"
                        size="small"
                        value={value}
                        onChange={handleChange}
                        sx={{
                          width: "45px", 
                          "& .MuiInputBase-root": {
                            height: '35px', 
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: '0', 
                            width: "40px", 
                            textAlign: 'center',
                            height: '35px', 
                          },
                          background: '#fff',
                        }}
                      />
                      <IconButton 
                        aria-label="shopping cart"
                        disabled={quantity < 1 || !value}
                        size="medium"
                        sx={{
                          background: theme.palette.secondary.main,
                          borderRadius: 0,
                          ":hover": {
                            backgroundColor: theme.palette.secondary.main,
                          },
                        }}
                        onClick={() =>
                          addToBasket(article, {
                            vertical: "top",
                            horizontal: "right",
                          })
                        }  
                      >
                        <ShoppingCartOutlinedIcon sx={{color: '#fff', fontSize: '17px'}} />
                      </IconButton>
                    </Box>
                  }
                  title={article.articleNumber}
                />
                <CardMedia
                  component="img"
                  height="194"
                  image={article.image || defaultImage}
                  alt="Paella dish"
                  sx={{ objectFit: "contain" }}
                />
                <CardContent>
                  <div>
                  {retailPrice ? (
                    price_2 > 0 && (
                      <>
                        <Typography variant="h6" 
                      sx={{
                        float: "left",
                        fontSize: "15px",
                        fontFamily: "Inter",
                        fontWeight: "normal",
                        width: 'max-content'
                      }}
                    >
                      Retail Price
                    </Typography>
                    <Typography variant="h6" 
                      sx={{
                        float: "right",
                        color: theme.palette.secondary.main,
                        fontSize: "15px",
                        fontFamily: "Inter",
                        fontWeight: "bold",
                        width: 'max-content'
                      }}
                    >
                      {price_2.toFixed(2)} {currency_2}
                    </Typography>
                      </>
                    )
                  ):(
                      <>
                        <Typography variant="h6" 
                      sx={{
                        float: "left",
                        fontSize: "15px",
                        fontFamily: "Inter",
                        fontWeight: "normal",
                        width: 'max-content'
                      }}
                    >
                      Price
                    </Typography>
                    <Typography variant="h6" 
                      sx={{
                        float: "right",
                        color: theme.palette.secondary.main,
                        fontSize: "15px",
                        fontFamily: "Inter",
                        fontWeight: "bold",
                        width: 'max-content'
                      }}
                    >
                      {price.toFixed(2)} {currency}
                    </Typography>
                      </>
                    )}
                  
                  </div>
                <div style={{marginTop: '30px'}}>
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontSize: "12px",
                            fontFamily: "Inter",
                            fontWeight: 500,
                          }}
                        >
                          {article.mfrName}
                        </Typography>
                      </div>
                    
                      <div>
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontSize: "12px",
                            fontFamily: "Inter",
                            fontWeight: "normal",
                          }}
                        >
                          Found via{" "}
                          <b style={{ fontWeight: 500 }}>Article number</b> (
                          {article.articleNumber})
                        </Typography>
                      </div>
                    
                </CardContent>
                <CardActions disableSpacing>
                <ProductTabs
                      article={article}
                      qty={value}
                      isFavorite={isFavorite}
                    />
                </CardActions>
            </Card>
          </>
        ):(
          <>
           <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              sx={{
                border: "4px solid #f1f1f1",
                marginBottom: 0.5,
                padding: 0.5,
              }}
            >
              <Box
                sx={{
                  background: "#f1f1f1",
                  pt: 0.5,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    sx={{
                      minWidth: "40px",
                      "&:focus, &:active": {
                        backgroundColor: "transparent",
                      },
                    }}
                    startIcon={<CustomIcon />}
                    onClick={handleClick(SlideTransition)}
                  ></Button>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontFamily: "Inter",
                      fontWeight: 500,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {article.articleNumber}
                  </Typography>
                </Item>
                <Item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title="Check to compare">
                    <Checkbox
                      disabled
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{
                        textTransform: "capitalize",
                        color: theme.palette.primary.main,
                        "&.Mui-checked": {
                          color: theme.palette.primary.main,
                        },
                        display: "flex",
                        flexDirection: "row",
                      }}
                    />
                  </Tooltip>
                  {isloadingStock ? (
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "1rem", width: "60px", mr: 2 }}
                    />
                  ) : (
                    retailPrice ? (
                      price_2 > 0 && (
                        <>
                           <Box
                              sx={{
                                display: "flex",
                                mr:2,
                                flexDirection: "column", // Ensures vertical layout
                                alignItems: "flex-start", // Align text to the start (left-aligned)
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "15px",
                                  fontFamily: "Inter",
                                  fontWeight: "normal",
                                  
                                }}
                              >
                                {price_2.toFixed(2)} {currency_2}
                              </Typography>

                              <Typography
                                sx={{
                                  fontSize: "10px",
                                  fontFamily: "Inter",
                                  fontWeight: "normal",
                                }}
                              >
                                Retail price
                              </Typography>
                            </Box>
                        </>
                      )
                    ):(
                      price > 0 && (
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontFamily: "Inter",
                            fontWeight: "normal",
                            display: "flex",
                            alignItems: "center",
                            mr: 2,
                          }}
                        >
                          {price.toFixed(2)} {currency}
                        </Typography>
                      )
                    )
                    
                  )}
                  {price &&
                    (isloadingStock ? (
                      <Stack direction="row" spacing={2} sx={{ width: "65px" }}>
                        <LoadingButton
                          loading
                          variant="outlined"
                          sx={{
                            width: "65px",
                          }}
                        >
                          Submit
                        </LoadingButton>
                      </Stack>
                    ) : (
                      <Button
                        startIcon={
                          <img
                            style={{ width: "27px", height: "27px" }}
                            src="./available_status.svg"
                            alt="product status icon"
                          />
                        }
                        sx={{
                          padding: "4px 2px !important",
                          background: quantity < 1 ? "#F33" : "#0C0",
                          "& .css-1d6wzja-MuiButton-startIcon": {
                            marginRight: "0px !important",
                            marginLeft: "0px !important",
                          },
                          borderRadius: 0,
                          ":hover": {
                            background: quantity < 1 ? "#F33" : "#0C0",
                          },
                        }}
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                      ></Button>
                    ))}
                  
                  <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: "none",
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <MenuList
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                    >
                      {warehouses.map((warehouse, index) => (
                            <MenuItem key={index}>
                              <span
                                style={{
                                  background:
                                  warehouse.situation < 1 ? "#F33" : "#0C0",
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "15px",
                                }}
                              ></span>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontFamily: "Inter",
                                  fontWeight: 500,
                                  display: "flex",
                                  alignItems: "center",
                                  ml: 1,
                                }}
                              >
                                {warehouse.serviceUnitCode}
                              </Typography>
                            </MenuItem>
                            ))}
                    </MenuList>
                  </Popover>
                  <TextField
                    id="standard-number"
                    disabled={!quantity}
                    size="medium"
                    variant="standard"
                    value={value}
                    onChange={handleChange}
                    sx={{
                      width: "50px",
                      background: "#fff",
                      marginLeft: 2,
                      marginRight: 2,
                      "& .MuiInput-underline:before": {
                        borderBottom: "none", // Remove the bottom border before hover
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottom: "none", // Remove the bottom border on hover
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none", // Remove the bottom border after clicking
                      },
                    }}
                    InputProps={{
                      sx: {
                        textAlign: "center",
                        border: "1px solid #d4d4d4",
                        height: "36px",
                        fontSize: "13px",
                        fontFamily: "Inter",
                        paddingLeft: ".9rem",
                        width: "100%",
                        "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                          {
                            borderBottom: "none", // Remove the bottom border on hover
                          },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none", // Remove the bottom border after clicking
                        },
                      },
                    }}
                  />
                  <Button
                     disabled={!value}
                    aria-label="basket"
                    size="medium"
                    sx={{
                      background: theme.palette.secondary.main,
                      borderRadius: 0,
                      ":hover": {
                        backgroundColor: theme.palette.secondary.main,
                      },
                    }}
                    onClick={() =>
                      addToBasket(article, {
                        vertical: "top",
                        horizontal: "right",
                      })
                    }
                  >
                    <ShoppingCartOutlinedIcon
                      sx={{ color: "#fff" }}
                      fontSize="medium"
                    />
                  </Button>
                </Item>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Item
                  sx={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{ width: "100px" }}
                    src={defaultImage}
                    alt="Product"
                  />
                </Item>
                <Item
                  sx={{
                    flex: 11,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <div>
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "13px",
                        fontFamily: "Inter",
                        fontWeight: 500,
                      }}
                    >
                      {article.mfrName}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "13px",
                        fontFamily: "Inter",
                        fontWeight: "normal",
                      }}
                    >
                      Found via{" "}
                      <b style={{ fontWeight: 500 }}>Article number</b> (
                      {article.articleNumber})
                    </Typography>
                    <Typography
                          sx={{
                            textAlign: "left",
                            fontSize: "12px",
                            fontFamily: "Inter",
                            fontWeight: "normal",
                          }}
                        >
                          
                          {article.description}
                        </Typography>
                  </div>
                  <div>
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "13px",
                        fontFamily: "Inter",
                        fontWeight: "normal",
                      }}
                    >
                     {article.width && (
                        <>
                          <b style={{ fontWeight: 500 }}>
                            Width
                          </b>
                          : {article.width};{" "}
                        </>
                      )}
                      {article.height && (
                        <>
                          <b style={{ fontWeight: 500 }}>
                            Height
                          </b>
                          : {article.height};{" "}
                        </>
                      )}
                      {article.dimensions && (
                        <>
                          <b style={{ fontWeight: 500 }}>
                            Dimensions
                          </b>
                          : {article.dimensions};{" "}
                        </>
                      )}
                    </Typography>
                  </div>
                </Item>
              </Box>
              <Box sx={{ display: "flex" }}>
                <ProductTabs article={article} qty={value} />
              </Box>
            </Grid>
          </Grid>
          <Snackbar
            open={state.open}
            onClose={handleClose}
            TransitionComponent={state.Transition}
            key={state.Transition.name}
            autoHideDuration={1200}
          >
            <Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
              {article.articleNumber} copied!
            </Alert>
          </Snackbar>
          <Snackbar
            key={vertical + horizontal}
            anchorOrigin={{ vertical, horizontal }}
            open={openNotification}
            autoHideDuration={1500}
            onClose={hideBasketNotification}
          >
            <Alert
              onClose={hideBasketNotification}
              severity="success"
              sx={{
                bgcolor: "#fff",
                width: "100%",
                boxShadow: "4px 5px 5px #cfcfcf",
              }}
            >
              <Card sx={{ display: "flex", boxShadow: "none" }}>
                <CardMedia
                  component="img"
                  sx={{ width: 120 }}
                  image={defaultImage}
                  alt="Live from space album cover"
                />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography
                      component="div"
                      variant="subtitle1"
                      sx={{
                        color: theme.palette.text.primary,
                        fontSize: "12px",
                        fontFamily: "Inter",
                        fontWeight: 500,
                      }}
                    >
                      {article.mfrName}{" "}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="div"
                      sx={{
                        textAlign: "left",
                        color: theme.palette.text.primary,
                        fontSize: "12px",
                        fontFamily: "Inter",
                        fontWeight: 500,
                      }}
                    >
                      Quantity x {value}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="div"
                      sx={{
                        textAlign: "left",
                        color: theme.palette.text.primary,
                        fontSize: "12px",
                        fontFamily: "Inter",
                        fontWeight: 500,
                      }}
                    >
                      Price: {price} {currency}
                    </Typography>
                  </CardContent>
                </Box>
              </Card>
            </Alert>
          </Snackbar>
          </>
        )}
         
        </>
      ) : (
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            sx={{
              border: "4px solid #f1f1f1",
              marginBottom: 0.5,
              padding: 0.5,
            }}
          >
            <Box
              sx={{
                background: "#f1f1f1",
                pt: 0.5,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "80px", height: "30px" }}
                />
              </Item>
              <Item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Check to compare">
                  <Skeleton
                    animation="wave"
                    sx={{ width: "30px", height: "30px", mr: 1 }}
                  />
                </Tooltip>
                <Skeleton
                  animation="wave"
                  sx={{ width: "80px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px" }}
                />
              </Item>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Item
                sx={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Skeleton
                  animation="wave"
                  sx={{ width: "5rem", height: "7rem" }}
                />
              </Item>
              <Item
                sx={{
                  flex: 11,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <div>
                  <Skeleton animation="wave" sx={{ width: "40px" }} />
                </div>
                <div>
                  <Skeleton animation="wave" sx={{ width: "50px" }} />
                </div>
                <div>
                  <Skeleton animation="wave" sx={{ width: "40rem" }} />
                </div>
                <div>
                  <Skeleton animation="wave" sx={{ width: "30rem" }} />
                  <Skeleton animation="wave" sx={{ width: "35rem" }} />
                  <Skeleton animation="wave" sx={{ width: "30rem" }} />
                </div>
              </Item>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              border: "4px solid #f1f1f1",
              marginBottom: 0.5,
              padding: 0.5,
            }}
          >
            <Box
              sx={{
                background: "#f1f1f1",
                pt: 0.5,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "80px", height: "30px" }}
                />
              </Item>
              <Item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Check to compare">
                  <Skeleton
                    animation="wave"
                    sx={{ width: "30px", height: "30px", mr: 1 }}
                  />
                </Tooltip>
                <Skeleton
                  animation="wave"
                  sx={{ width: "80px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px" }}
                />
              </Item>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Item
                sx={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Skeleton
                  animation="wave"
                  sx={{ width: "5rem", height: "7rem" }}
                />
              </Item>
              <Item
                sx={{
                  flex: 11,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <div>
                  <Skeleton animation="wave" sx={{ width: "40px" }} />
                </div>
                <div>
                  <Skeleton animation="wave" sx={{ width: "50px" }} />
                </div>
                <div>
                  <Skeleton animation="wave" sx={{ width: "40rem" }} />
                </div>
                <div>
                  <Skeleton animation="wave" sx={{ width: "30rem" }} />
                  <Skeleton animation="wave" sx={{ width: "35rem" }} />
                  <Skeleton animation="wave" sx={{ width: "30rem" }} />
                </div>
              </Item>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              border: "4px solid #f1f1f1",
              marginBottom: 0.5,
              padding: 0.5,
            }}
          >
            <Box
              sx={{
                background: "#f1f1f1",
                pt: 0.5,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "80px", height: "30px" }}
                />
              </Item>
              <Item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Check to compare">
                  <Skeleton
                    animation="wave"
                    sx={{ width: "30px", height: "30px", mr: 1 }}
                  />
                </Tooltip>
                <Skeleton
                  animation="wave"
                  sx={{ width: "80px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px" }}
                />
              </Item>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Item
                sx={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Skeleton
                  animation="wave"
                  sx={{ width: "5rem", height: "7rem" }}
                />
              </Item>
              <Item
                sx={{
                  flex: 11,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <div>
                  <Skeleton animation="wave" sx={{ width: "40px" }} />
                </div>
                <div>
                  <Skeleton animation="wave" sx={{ width: "50px" }} />
                </div>
                <div>
                  <Skeleton animation="wave" sx={{ width: "40rem" }} />
                </div>
                <div>
                  <Skeleton animation="wave" sx={{ width: "30rem" }} />
                  <Skeleton animation="wave" sx={{ width: "35rem" }} />
                  <Skeleton animation="wave" sx={{ width: "30rem" }} />
                </div>
              </Item>
            </Box>
          </Grid>
        </Grid>
      )}
   </>
  )

};
export default LocalProductItem;